// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-docs-fsharp-js": () => import("./../../../src/pages/docs/fsharp.js" /* webpackChunkName: "component---src-pages-docs-fsharp-js" */),
  "component---src-pages-docs-ocaml-js": () => import("./../../../src/pages/docs/ocaml.js" /* webpackChunkName: "component---src-pages-docs-ocaml-js" */),
  "component---src-pages-docs-rescript-js": () => import("./../../../src/pages/docs/rescript.js" /* webpackChunkName: "component---src-pages-docs-rescript-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-get-started-js": () => import("./../../../src/templates/get-started.js" /* webpackChunkName: "component---src-templates-get-started-js" */)
}

